import { UUID } from 'angular2-uuid';
import * as _ from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { DocTypeConstants } from '../../constants/doc-types';
import { ILibraryUpdateDoc } from '../../model/content-api/library-update-doc';
import { ContentLibraryInterfaces } from '../../model/content/content-library.interfaces';
import { ContentModels } from '../../model/content/content.models';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { SyncGatewayInterfaces } from '../../model/sync-gateway/sync-gateway.interfaces';
import { JwtService } from '../authentication/jwt.service';
import { CacheService } from '../cache/cache.service';
import { MediaApiService } from '../media/media-api.service';
import { MicroserviceApiBaseService } from '../micro-api/microservice-api-base.service';
import { StateManager } from '../state/state-manager';

import ContentItem = ContentModels.ContentItem;
import LibraryFolder = ContentModels.LibraryFolder;
import LayoutRoot = ContentModels.LayoutRoot;
import Container = ContentModels.Container;
import LinkTo = ContentModels.LinkTo;
import ContentPackage = ContentModels.ContentPackage;
@Injectable()
export class ContentApiService extends MicroserviceApiBaseService {
  protected readonly contentApiUrl: string;
  protected mediaApiUrl: string;
  protected mediaApiService: MediaApiService;

  constructor(
    http: HttpClient,
    jwtService: JwtService,
    mediaApiService: MediaApiService,
    stateManager: StateManager,
    protected cacheService: CacheService
  ) {
    super(http, jwtService, stateManager);
    this.contentApiUrl = environment.contentApi.url;
    this.mediaApiUrl = environment.mediaApi.url;
    this.mediaApiService = mediaApiService;
  }

  getAllByNamespaceType<T>(
    namespace: string,
    type: string,
    docClass: new (data?: any) => T,
    filterByAccountIds: string[] = [],
    filterByFacilityIds: string[] = [],
    ignoreCache = false
  ): Observable<T[]> {
    const accountIds = filterByAccountIds.filter(id => !!id);
    const facilityIds = filterByFacilityIds.filter(id => !!id);
    const isTypeRequest = !accountIds.length && !facilityIds.length;
    const isMultipleAccountsRequest =
      accountIds.length > 1 && !facilityIds.length;
    const isMultipleFacilitiesRequest =
      accountIds.length === 1 && facilityIds.length > 1;
    const isSingleAccountRequest =
      accountIds.length === 1 && !facilityIds.length;
    const isSingleFacilityRequest =
      accountIds.length === 1 && facilityIds.length === 1;

    if (
      !isTypeRequest &&
      !isMultipleAccountsRequest &&
      !isMultipleFacilitiesRequest &&
      !isSingleAccountRequest &&
      !isSingleFacilityRequest
    ) {
      return throwError(new Error('Invalid document request'));
    }

    if (isMultipleAccountsRequest) {
      return this.getAllByNamespaceType<T>(namespace, type, docClass).pipe(
        map(docs =>
          docs.filter(
            doc =>
              accountIds.includes(doc['_id']) ||
              accountIds.includes(doc['account_id'])
          )
        )
      );
    }

    if (isMultipleFacilitiesRequest) {
      return this.getAllByNamespaceType<T>(
        namespace,
        type,
        docClass,
        accountIds
      ).pipe(
        map(docs => {
          return docs.filter(
            doc =>
              facilityIds.includes(doc['_id']) ||
              facilityIds.includes(
                doc['facility_id'] ||
                  !!_.intersection(
                    facilityIds,
                    (doc['facility_ids'] || []).length
                  )
              )
          );
        })
      );
    }

    const accountId = accountIds.length ? accountIds[0] : undefined;
    const facilityId = facilityIds.length ? facilityIds[0] : undefined;

    let cachedData = null;
    if (!ignoreCache) {
      cachedData = this.cacheService.getCache(
        namespace,
        type,
        accountId,
        facilityId
      );
    }

    const queryParams: Record<string, string> = {};
    if (accountIds && !!accountIds.length) {
      queryParams.account_id = accountIds[0];
    }
    if (facilityIds && !!facilityIds.length) {
      queryParams.facility_id = facilityIds[0];
    }

    const apiResponseObservable = cachedData
      ? of(<T[]>cachedData)
      : this.get<T[]>(
          this.generateUrl(
            this.determineDbApiDocPath(namespace, {
              type: type,
              isSingleResource: false,
            }),
            queryParams
          )
        );

    return apiResponseObservable.pipe(
      map((results: T[]) => {
        this.cacheService.setCache(
          results,
          namespace,
          type,
          accountId,
          facilityId
        );

        const cacheToReturn = this.cacheService.getCache(
          namespace,
          type,
          accountId,
          facilityId
        );

        return cacheToReturn
          ? cacheToReturn.map(cacheResult => new docClass(cacheResult))
          : [];
      })
    );
  }

  protected getById<T>(
    namespace: string,
    type: string,
    id: string,
    docClass: new (data?: any) => T
  ): Observable<T> {
    // If we are getting a single doc by ID, it's assumed that doc should be fresh
    return this.get<T>(
      this.determineDbApiDocPath(namespace, {
        type: type,
        id: id,
      })
    ).pipe(
      map(result => {
        this.cacheService.updateSingleDoc(result);
        return new docClass(result);
      })
    );
  }

  deleteDoc(
    namespace: string,
    type: string,
    id: string,
    parameters?: Record<string, string>
  ): Observable<boolean> {
    return this.delete(
      this.determineDbApiDocPath(namespace, {
        type: type,
        id: id,
      }),
      parameters
    ).pipe(
      tap(() => this.cacheService.updateSingleDoc({ _id: id, _deleted: true }))
    );
  }

  getAttachmentFromDataUri(
    attachmentName: string,
    dataUri: string
  ): SyncGatewayAttachment {
    if (!dataUri) {
      return null;
    }

    const match = dataUri.match(/^data:([^;]+);base64,(.*)/);
    if (!match) {
      return null;
    }

    const contentType = match[1];
    const base64Data = match[2];
    return {
      name: attachmentName,
      base64Data,
      contentType,
    };
  }

  partialsRequest<T>(
    namespace: string,
    type: string,
    body: object
  ): Observable<T> {
    return this.post<T>(
      this.determineDbApiDocPath(namespace, { type: type, isPartials: true }),
      body
    );
  }

  forceUpload(item: ContentLibraryInterfaces.IChangeEvent) {
    return this.mediaApiService.forceUpload(item);
  }

  forceValidate(item: ContentLibraryInterfaces.IChangeEvent) {
    return this.mediaApiService.forceValidate(item);
  }

  protected getLibraryPaths(): Observable<string[]> {
    return this.get<string[]>(`${this.contentApiUrl}/library/paths`);
  }

  protected getLibraryItems(
    path: string
  ): Observable<ContentLibraryInterfaces.IContentStatsResult[]> {
    return this.post<ContentLibraryInterfaces.IContentStatsResult[]>(
      `${this.contentApiUrl}/library/items`,
      {
        library_path: path,
      }
    );
  }

  protected getCsv(): Observable<string> {
    return this.get<string>(`${this.contentApiUrl}/type/content-item/csv`);
  }

  protected upsertSingleLinkTo(
    linkTo: LinkTo,
    attachment?: SyncGatewayAttachment
  ): Observable<LinkTo> {
    // clean attachments in case there is a blob_ and non-blob_ version of the same attachment type
    if (attachment && linkTo._attachments) {
      const blobName = `blob_/${attachment.name}`;
      delete linkTo._attachments[blobName];
      delete linkTo._attachments[attachment.name];
      delete linkTo[attachment.name];
    }

    linkTo._id = linkTo._id || UUID.UUID();

    return this.put<LinkTo>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.LINK_TO,
        id: linkTo._id,
      }),
      linkTo
    ).pipe(
      mergeMap((resultLinkto: LinkTo) => {
        if (!resultLinkto) {
          return throwError(
            new Error('Update failed. Please refresh the page and try again.')
          );
        }

        if (attachment) {
          // The eventing function may change the document before the attachment can be updated
          // resulting in a new Sync Gateway revision
          // Temporarily adding short timeout to allow for eventing
          const date = Date.now();
          let curDate = null;
          do {
            curDate = Date.now();
          } while (curDate - date < 5000);
          return this.getById<LinkTo>(
            DocTypeConstants.NAMESPACES.CONTENT,
            DocTypeConstants.TYPES.CONTENT.LINK_TO,
            resultLinkto._id,
            LinkTo
          ).pipe(
            mergeMap((updatedLinkTo: LinkTo) => {
              return this.attachmentUpdate(attachment, updatedLinkTo).pipe(
                mergeMap((returnedLinkTo: LinkTo) => {
                  return of(returnedLinkTo);
                })
              );
            })
          );
        }

        return of(resultLinkto);
      })
    );
  }

  protected upsertSingleContainer(
    container: Container,
    attachment?: SyncGatewayAttachment
  ): Observable<Container> {
    // clean attachments in case there is a blob_ and non-blob_ version of the same attachment type
    if (attachment && container._attachments) {
      const blobName = `blob_/${attachment.name}`;
      delete container._attachments[blobName];
      delete container._attachments[attachment.name];
      delete container[attachment.name];
    }

    container._id = container._id || UUID.UUID();

    return this.put<Container>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.CONTAINER,
        id: container._id,
      }),
      container
    ).pipe(
      mergeMap((resultContainer: Container) => {
        if (!resultContainer) {
          return throwError(
            new Error('Update failed. Please refresh the page and try again.')
          );
        }

        if (attachment) {
          // The eventing function may change the document before the attachment can be updated
          // resulting in a new Sync Gateway revision
          // Temporarily adding short timeout to allow for eventing
          const date = Date.now();
          let curDate = null;
          do {
            curDate = Date.now();
          } while (curDate - date < 5000);
          return this.getById<Container>(
            DocTypeConstants.NAMESPACES.CONTENT,
            DocTypeConstants.TYPES.CONTENT.CONTAINER,
            resultContainer._id,
            Container
          ).pipe(
            mergeMap((updatedContainer: Container) => {
              return this.attachmentUpdate(attachment, updatedContainer).pipe(
                mergeMap((returnedContainer: Container) => {
                  return of(returnedContainer);
                })
              );
            })
          );
        }
        return of(resultContainer);
      })
    );
  }

  protected upsertSingleLayoutRoot(
    layoutRoot: LayoutRoot
  ): Observable<LayoutRoot> {
    layoutRoot._id = layoutRoot._id || UUID.UUID();
    return this.put(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.LAYOUT_ROOT,
        id: layoutRoot._id,
      }),
      layoutRoot
    );
  }

  protected upsertSingleFolder(
    folder: LibraryFolder
  ): Observable<LibraryFolder> {
    folder._id = folder._id || UUID.UUID();
    return this.put(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.LIBRARY_FOLDER,
        id: folder._id,
      }),
      folder
    );
  }

  protected upsertSinglePackage(
    contentPackage: ContentPackage
  ): Observable<ContentPackage> {
    contentPackage._id = contentPackage._id || UUID.UUID();
    return this.put(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.PACKAGE,
        id: contentPackage._id,
      }),
      contentPackage
    );
  }

  protected upsertSingleContentItem(
    contentItem: ContentItem,
    attachment?: SyncGatewayAttachment
  ): Observable<ContentItem> {
    // clean attachments in case there is a blob_ and non-blob_ version of the same attachment type
    if (attachment && contentItem._attachments) {
      const blobName = `blob_/${attachment.name}`;
      delete contentItem._attachments[blobName];
      delete contentItem._attachments[attachment.name];
      delete contentItem[attachment.name];
    }

    contentItem._id = contentItem._id || UUID.UUID();

    // namespace-type put route does an upsert
    return this.put<ContentItem>(
      this.determineDbApiDocPath(DocTypeConstants.NAMESPACES.CONTENT, {
        type: DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
        id: contentItem._id,
      }),
      contentItem
    ).pipe(
      mergeMap((resultItem: ContentItem) => {
        if (!resultItem) {
          return throwError(
            new Error('Update failed. Please refresh the page and try again.')
          );
        }

        if (attachment) {
          // The eventing function may change the document before the attachment can be updated
          // resulting in a new Sync Gateway revision
          // Temporarily adding short timeout to allow for eventing
          const date = Date.now();
          let curDate = null;
          do {
            curDate = Date.now();
          } while (curDate - date < 5000);
          return this.getById<ContentItem>(
            DocTypeConstants.NAMESPACES.CONTENT,
            DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
            contentItem._id,
            ContentItem
          ).pipe(
            mergeMap((updatedItem: ContentItem) => {
              return this.attachmentUpdate(attachment, updatedItem).pipe(
                mergeMap((returnedItem: ContentItem) => {
                  return of(returnedItem);
                })
              );
            })
          );
        }

        return of(resultItem);
      })
    );
  }

  protected getContentSearchResults(
    searchText: string
  ): Observable<ContentLibraryInterfaces.IContentStatsResult[]> {
    return this.post<ContentLibraryInterfaces.IContentStatsResult[]>(
      `${this.contentApiUrl}/library/search`,
      {
        search_text: searchText,
      }
    );
  }

  protected updateLibraryContentPath(
    oldLibraryPath: string,
    newLibraryPath: string
  ): Observable<ILibraryUpdateDoc> {
    return this.put<ILibraryUpdateDoc>(`${this.contentApiUrl}/library/paths`, {
      old_library_path: oldLibraryPath,
      new_library_path: newLibraryPath,
    });
  }

  protected moveLibraryContentItems(
    newLibraryPath: string,
    items: ContentLibraryInterfaces.IContentStatsResult[]
  ): Observable<ILibraryUpdateDoc> {
    return this.put<ILibraryUpdateDoc>(`${this.contentApiUrl}/library/move`, {
      new_library_path: newLibraryPath,
      items: items,
    });
  }

  protected deleteLibraryContentItems(
    items: ContentLibraryInterfaces.IContentStatsResult[]
  ): Observable<ILibraryUpdateDoc> {
    return this.put<ILibraryUpdateDoc>(`${this.contentApiUrl}/library/delete`, {
      items: items,
    });
  }

  private attachmentUpdate(
    attachment: SyncGatewayAttachment | undefined,
    doc: ContentItem | LinkTo | Container
  ) {
    return this.mediaApiService
      .updateAttachment(attachment, doc._id, doc._rev)
      .pipe(
        mergeMap((attachmentResult: SyncGatewayInterfaces.IUpdateResult) => {
          if (attachmentResult.ok) {
            let type;
            if (doc instanceof ContentItem) {
              type = ContentItem;
            } else if (doc instanceof Container) {
              type = Container;
            } else if (doc instanceof LinkTo) {
              type = LinkTo;
            }
            return this.getById(doc.doc_namespace, doc.doc_type, doc._id, type);
          }
          return throwError(
            new Error('Update failed. Please refresh the page and try again.')
          );
        })
      );
  }
}

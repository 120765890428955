import { UUID } from 'angular2-uuid';
import { forkJoin, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DocTypeConstants } from '../../constants/doc-types';
import { ILibraryUpdateDoc } from '../../model/content-api/library-update-doc';
import { ContentLibraryInterfaces } from '../../model/content/content-library.interfaces';
import { ContentInterfaces } from '../../model/content/content.interfaces';
import { ContentModels } from '../../model/content/content.models';
import { SyncGatewayAttachment } from '../../model/sync-gateway/sync-gateway-attachment';
import { JwtService } from '../authentication/jwt.service';
import { AwsService } from '../aws/aws.service';
import { CacheService } from '../cache/cache.service';
import { ContentApiService } from '../content-api/content-api.service';
import { MediaApiService } from '../media/media-api.service';
import { StateManager } from '../state/state-manager';
import { UploadService } from '../upload/upload.service';

import ContentItem = ContentModels.ContentItem;
import LibraryFolder = ContentModels.LibraryFolder;
@Injectable()
export class ContentLibraryService extends ContentApiService {
  constructor(
    http: HttpClient,
    jwtService: JwtService,
    cacheService: CacheService,
    mediaApiService: MediaApiService,
    stateManager: StateManager,
    private awsService: AwsService,
    private uploadService: UploadService
  ) {
    super(http, jwtService, mediaApiService, stateManager, cacheService);
  }

  getContentLibraryItems(
    path: string
  ): Observable<ContentLibraryInterfaces.IContentStatsResult[]> {
    return this.getLibraryItems(path);
  }

  getContentCsv(): Observable<string> {
    return this.getCsv();
  }

  getSearchResults(
    searchText: string
  ): Observable<ContentLibraryInterfaces.IContentStatsResult[]> {
    return this.getContentSearchResults(searchText);
  }

  getAllContentItems(): Observable<ContentItem[]> {
    return this.getAllByNamespaceType(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
      ContentItem
    );
  }

  getPartialsContentItems(ids: string[]): Observable<ContentItem[]> {
    return this.partialsRequest(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
      {
        ids: ids,
      }
    );
  }

  getContentItem(id: string): Observable<ContentItem> {
    return this.getById<ContentItem>(
      DocTypeConstants.NAMESPACES.CONTENT,
      DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM,
      id,
      ContentItem
    );
  }

  getAllLibraryPaths(): Observable<string[]> {
    return this.getLibraryPaths();
  }

  createFolder(parentPath: string, title: string): Observable<LibraryFolder> {
    const emptyFolder = new LibraryFolder();
    emptyFolder.library_path =
      parentPath === '/' ? `/${title.trim()}` : `${parentPath}/${title.trim()}`;
    return this.upsertSingleFolder(emptyFolder);
  }

  updateLibraryPath(
    oldLibraryPath: string,
    newLibraryPath: string
  ): Observable<ILibraryUpdateDoc> {
    return this.updateLibraryContentPath(oldLibraryPath, newLibraryPath);
  }

  upsertContentItem(
    contentItem: ContentItem,
    attachment?: SyncGatewayAttachment
  ): Observable<ContentItem> {
    return this.upsertSingleContentItem(contentItem, attachment).pipe(
      mergeMap((upsertedContentItem: ContentItem) => {
        this.cacheService.updateSingleDoc(upsertedContentItem);
        return of(upsertedContentItem);
      })
    );
  }

  moveLibraryItems(
    newLibraryPath: string,
    items: ContentLibraryInterfaces.IContentStatsResult[]
  ): Observable<ILibraryUpdateDoc> {
    return this.moveLibraryContentItems(newLibraryPath, items);
  }

  deleteContentItem(contentItem: ContentItem): Observable<ContentItem> {
    contentItem.archived = true;
    return this.upsertContentItem(contentItem);
  }

  deleteLibraryItems(
    items: ContentLibraryInterfaces.IContentStatsResult[]
  ): Observable<ILibraryUpdateDoc> {
    return this.deleteLibraryContentItems(items);
  }

  uploadContentFile(contentItem: ContentItem, file: File) {
    const uuid = UUID.UUID();
    const s3Key = !!contentItem.s3_key
      ? contentItem.s3_key
      : `${uuid}-${file.name.replace(/\s/g, '_')}`;

    if (!s3Key || !contentItem || !file) {
      return;
    }

    // Create toaster message for progress bar
    this.uploadService.updateActiveUploads(
      contentItem._id,
      s3Key,
      file.name,
      file.size
    );

    // run uploader
    return forkJoin([
      this.awsService.uploadNewPublicFile(s3Key, file),
      this.getContentItem(contentItem._id),
    ]).pipe(
      mergeMap(([s3, item]: [any, ContentItem]) => {
        this.setS3Variables(item, s3Key, s3);
        delete item.hosting_status;
        return this.upsertContentItem(item);
      })
    );
  }

  private setS3Variables(
    item: ContentInterfaces.IContentItem,
    s3_key: string,
    s3
  ) {
    item.s3_key = s3_key;
    item.s3_etag = (s3.etag || '').replace(/"/g, '');
  }
}

import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ITreeState } from '@circlon/angular-tree-component';

import { DocTypeConstants } from '../../../../constants/doc-types';
import { ContentLibraryInterfaces } from '../../../../model/content/content-library.interfaces';
import { ContentInterfaces } from '../../../../model/content/content.interfaces';
import { ContentModels } from '../../../../model/content/content.models';
import { ISortColumn } from '../../../../shared/components/sortable-report/sortable-report.component';
import { ContentLibraryBaseComponent } from '../../content-library-base/content-library-base.component';

@Component({
  selector: 'app-content-library-list',
  templateUrl: './content-library-list.component.html',
  styleUrls: ['./content-library-list.component.scss'],
})
export class ContentLibraryListComponent
  extends ContentLibraryBaseComponent
  implements OnChanges {
  @ViewChild('addFolderModal')
  addFolderModal: ModalDirective;
  @ViewChild('newFolderNameInput')
  newFolderNameInput: ElementRef;
  @ViewChild('moveModal') moveModal: ModalDirective;
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('deleteNotAllowedModal')
  deleteNotAllowedModal: ModalDirective;

  @Input()
  isSearchResults = false;
  @Input()
  libraryPath = '/';
  @Input()
  allLibraryPaths: string[];
  @Input()
  libraryItems: ContentLibraryInterfaces.IContentStatsResult[] = [];
  @Input()
  allContentItems: ContentInterfaces.IContentItem[];
  @Output()
  itemClicked: EventEmitter<ContentLibraryInterfaces.IContentStatsResult> = new EventEmitter<ContentLibraryInterfaces.IContentStatsResult>();
  @Output()
  changeEvent: EventEmitter<ContentLibraryInterfaces.IChangeEvent> = new EventEmitter<ContentLibraryInterfaces.IChangeEvent>();

  changeDetails: ContentLibraryInterfaces.IChangeEvent = {};
  selectedItems: ContentLibraryInterfaces.IContentStatsResult[] = [];
  linkedItems: ContentLibraryInterfaces.IContentStatsResult[] = [];
  newFolderName = '';
  moveToPath = '';

  moveFolderTreeNodes: { id: string; name: string }[];
  moveFolderTreeOptions = {
    idField: 'id',
    childrenField: 'children',
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.selectMoveToFolder(node.data);
        },
      },
      allowDrag: false,
    },
  };
  moveFolderTreeState: ITreeState = {};

  contentItem: ContentModels.ContentItem;

  columns: ISortColumn[] = [
    {
      title: 'Name',
      key: 'title',
      sort: 'asc',
      type: 'string',
    },
    {
      title: 'Content Items',
      key: 'total_content_items',
      sort: '',
      type: 'number',
    },
    {
      title: 'Hosting Status',
      key: 'hosting_status',
      sort: '',
      type: 'string',
    },
    {
      title: 'Created Date',
      key: 'created_date',
      sort: '',
      type: 'date',
    },
    {
      title: 'Platform(s)',
      key: 'platforms',
      sort: '',
      type: 'string',
    },
  ];

  constructor() {
    super();
  }

  exportContentCsv() {
    console.log('Exporting Content CSV.');
    this.changeEvent.emit({ downloadCsv: {} });
  }

  ngOnChanges() {
    this.clearChanges(true);
  }

  changeSort(sortColumn: ISortColumn) {
    this.columns.forEach(c => {
      if (c.key === sortColumn.key) {
        c.sort = c.sort === 'asc' ? 'desc' : 'asc';
        return;
      }

      c.sort = '';
    });

    this.sortData(sortColumn);
  }

  sortData(sortColumn?: ISortColumn) {
    // apply sorting
    if (sortColumn) {
      this.libraryItems.sort((a, b) => {
        const val1 =
          sortColumn.type === 'number' && a[sortColumn.key]
            ? Number(a[sortColumn.key] || 0)
            : (a[sortColumn.key] || '').toString().toLowerCase();
        const val2 =
          sortColumn.type === 'number' && b[sortColumn.key]
            ? Number(b[sortColumn.key] || 0)
            : (b[sortColumn.key] || '').toString().toLowerCase();
        if (val1 > val2) {
          return sortColumn.sort === 'desc' ? -1 : 1;
        } else if (val1 < val2) {
          return sortColumn.sort === 'asc' ? -1 : 1;
        }
        return 0;
      });
    }
  }

  /**
   * Events
   */

  emitAndClearChange(clearSelected: boolean = true) {
    this.emitChanges();
    this.clearChanges(clearSelected);
  }

  emitChanges() {
    console.log(this.changeDetails);
    this.changeEvent.emit(this.changeDetails);
  }

  clearChanges(clearSelected: boolean = true) {
    this.changeDetails = {};
    this.newFolderName = '';
    this.moveToPath = '';
    this.contentItem = null;
    if (clearSelected) {
      this.selectedItems = [];
    }
  }

  emitItemClicked(item: ContentLibraryInterfaces.IContentStatsResult) {
    this.clearChanges(true);
    if (
      item._id &&
      item.doc_type !== DocTypeConstants.TYPES.CONTENT.LIBRARY_FOLDER
    ) {
      this.changeDetails.editContentItem = {
        itemId: item._id,
        library_path: item.library_path,
      };
      this.emitAndClearChange();
    } else {
      this.itemClicked.emit(item);
    }
  }

  cancelModal() {
    this.clearChanges(false);
    this.addFolderModal.hide();
    this.moveModal.hide();
    this.deleteModal.hide();
    this.deleteNotAllowedModal.hide();
  }

  /**
   * Rename folder
   */

  editFolderName(item: ContentLibraryInterfaces.IContentStatsResult) {
    if (item._id) {
      return;
    }

    this.changeDetails = {
      renameFolder: {
        oldTitle: item.title,
        newTitle: item.title,
      },
    };
  }

  saveFolderName(item: ContentLibraryInterfaces.IContentStatsResult) {
    if (!this.newFolderNameIsValid('edit')) {
      return;
    }

    item.title = this.changeDetails.renameFolder.newTitle;
    this.emitAndClearChange();
  }

  cancelFolderNameChange() {
    this.clearChanges(false);
  }

  /**
   * Add folder
   */
  showAddFolderModal() {
    this.newFolderName = '';
    this.changeDetails = {
      addFolder: {
        newFolderName: '',
      },
    };

    this.addFolderModal.show();
    setTimeout(() => this.newFolderNameInput.nativeElement.focus(), 500);
  }

  newFolderNameIsValid(type: 'add' | 'edit') {
    const name =
      type === 'add'
        ? this.newFolderName
        : _.get(this.changeDetails, 'renameFolder.newTitle', '');
    if (!name) {
      return false;
    }

    let isUnique = true;
    if (type === 'edit') {
      const itemBeingEdited = this.libraryItems.find(
        item =>
          item.title === _.get(this.changeDetails, 'renameFolder.oldTitle', '')
      );
      isUnique = !this.libraryItems.find(
        item => itemBeingEdited._id !== item._id && item.title === name
      );
    }

    if (type === 'add') {
      isUnique = !this.libraryItems.find(item => item.title === name);
    }

    const validCharacters = !this.hasInvalidCharacter(name);

    return validCharacters && isUnique;
  }

  submitNewFolder() {
    if (!this.newFolderNameIsValid('add')) {
      return;
    }

    this.changeDetails.addFolder.newFolderName = this.newFolderName;
    this.emitAndClearChange();
    this.addFolderModal.hide();
  }

  /**
   * Add content
   */
  addContent() {
    this.changeDetails.addContentItem = { item: null };
    this.emitAndClearChange();
  }

  /**
   * Select items for delete or move
   */

  isSelected(item: ContentLibraryInterfaces.IContentStatsResult): boolean {
    if (item._id) {
      return !!this.selectedItems.find(val => val._id === item._id);
    }

    return !!this.selectedItems.find(val => val.title === item.title);
  }

  toggleSelection(item: ContentLibraryInterfaces.IContentStatsResult) {
    if (item._id && this.selectedItems.find(val => val._id === item._id)) {
      this.selectedItems = this.selectedItems.filter(
        val => val._id !== item._id
      );
      return;
    }

    if (!item._id && this.selectedItems.find(val => val.title === item.title)) {
      this.selectedItems = this.selectedItems.filter(
        val => val.title !== item.title
      );
      return;
    }

    this.selectedItems.push(item);
  }

  allAreSelected() {
    return (
      this.libraryItems.length &&
      this.selectedItems.length === this.libraryItems.length
    );
  }

  toggleAllSelections() {
    // Check everything if some but not all are already checked
    if (
      this.selectedItems.length &&
      this.selectedItems.length < this.libraryItems.length
    ) {
      this.selectedItems = [];
    }

    this.libraryItems.forEach(item => {
      this.toggleSelection(item);
    });
  }

  /**
   * Move folder
   */
  showMoveFolderModal() {
    const nodeMap = {};

    const uniquePaths = _.uniq(this.allLibraryPaths).sort();

    ['/', ...uniquePaths].forEach(path => {
      const isRoot = path === '/';
      const pathParts = path.split('/');
      const folderName = isRoot ? 'Root' : pathParts[pathParts.length - 1];
      const parentPath = isRoot
        ? null
        : '/' + folderName === path
        ? '/'
        : path.slice(0, path.length - folderName.length - 1);
      nodeMap[path] = {
        id: path,
        name: folderName,
        parentPath,
        children: [],
      };
    });

    uniquePaths.forEach(path => {
      if (path === '/') {
        return;
      }

      const node = nodeMap[path];
      const parentNode = node ? nodeMap[node.parentPath] : null;

      if (node && parentNode) {
        parentNode.children.push(node);
      }
    });

    this.moveFolderTreeState = {};
    this.moveFolderTreeNodes = [nodeMap['/']];

    this.moveModal.show();
  }

  selectMoveToFolder(event: { id: string; text: string }) {
    this.moveToPath = event.id;
  }

  submitMove() {
    this.changeDetails = {
      moveItems: {
        items: this.selectedItems,
        newPath: this.moveToPath,
      },
    };

    this.emitAndClearChange();
    this.moveModal.hide();
  }

  /**
   * Delete folder
   */
  showDeleteFolderModal() {
    const nonEmptyFolders = this.selectedItems.filter(
      item => !item._id && item.total_content_items > 0
    );

    if (nonEmptyFolders.length > 0) {
      this.changeDetails = {
        error: {
          message:
            'Not all folders are empty. Only empty folders can be deleted.',
        },
      };
      this.emitAndClearChange();
      return;
    }

    const hasLinkedItems = !!this.selectedItems.find(
      item => !!(item.link_to_paths || []).length
    );
    if (hasLinkedItems) {
      this.linkedItems = this.selectedItems.filter(
        item => !!(item.link_to_paths || []).length
      );
      this.deleteNotAllowedModal.show();
      return;
    }

    this.changeDetails = {
      deleteItems: {
        libraryItems: this.selectedItems,
      },
    };

    this.deleteModal.show();
  }

  submitDelete() {
    this.emitAndClearChange();
    this.deleteModal.hide();
  }

  /**
   * Search
   */

  updateSearch(searchText: string) {
    let errorMessage = null;

    if (!searchText) {
      this.changeEvent.emit({
        search: { searchText: '' },
      });
      return;
    }

    if (searchText.length < 3) {
      errorMessage = 'Please enter at least 3 characters to search.';
    } else if (this.hasInvalidCharacter(searchText)) {
      errorMessage =
        'Search text cannot contain double quotes, forward slashes, or tilda characters.';
    }

    if (errorMessage) {
      this.changeEvent.emit({
        error: { message: errorMessage },
      });
      return;
    }

    this.changeEvent.emit({
      search: { searchText },
    });
  }

  /**
   * Type Checks
   */

  isLibraryFolder(
    item: ContentInterfaces.ILibraryFolder | ContentInterfaces.IContentItem
  ) {
    return item.doc_type === DocTypeConstants.TYPES.CONTENT.LIBRARY_FOLDER;
  }

  isContentItem(
    item: ContentInterfaces.ILibraryFolder | ContentInterfaces.IContentItem
  ) {
    return item.doc_type === DocTypeConstants.TYPES.CONTENT.CONTENT_ITEM;
  }

  platformFormat(item: ContentLibraryInterfaces.IContentStatsResult): string {
    if (!item || item.platforms == null) {
      return '';
    }
    const formattedPlatforms = Object.keys(item.platforms)
      .map(platform =>
        item.platforms[platform] ? platform.toUpperCase() : null
      )
      .filter(val => !!val)
      .join(', ');

    return formattedPlatforms;
  }
}
